@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-image: url(https://azfstorage593.z13.web.core.windows.net/bitsign_bg.jpg);
    background-size: cover;
	font-family: 'Roboto', sans-serif;
}

.App {
	margin: 20px;
}

.init {
	max-width: 520px;
    margin: 50px auto;
	text-align: center;
}

.init img {
	width: 300px;
}

.init p {
	font-family: 'Roboto', sans-serif;
	font-weight: bolder;
	margin-top: 70px;
}

.initForm {
	display: flex;
    flex-direction: column;
	align-items: center;
    gap: 20px;
    margin: 30px 50px;
}

.initForm input {
	width: 350px;
    text-align: center;
}

.buttongroup button{
  margin: 0px 30px;
}

.recaptcha {
	margin: 30px 0px;
}

.error-captcha {
	color: red;
	margin-bottom: 5px;
}