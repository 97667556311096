.dot {
    height: 15px;
    width: 15px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}
.dot-new {
    background-color: #ccc;
}
.dot-current {
    background-color: #337ab7;
    height: 18px;
    width: 18px;
}
.dot-past {
    background-color: #888;
}
.dot-blank {
    background-color: #fff;
    opacity: 0;
}