.sigCanvas, .signatureCanvas{
    border: 1px solid rgb(0, 0, 0);
    width: 300px;
    height: 150px;
}
.sumCanvas{
  border: 1px solid rgb(0, 0, 0);
  width: 170px;
  height: 100px;
}
  /*------
/* canvas[className*="fabric-canvas"] {
    position: absolute;
} */
