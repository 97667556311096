body {
	background-image: url(https://azfstorage593.z13.web.core.windows.net/bitsign_bg.jpg);
    background-size: cover;
}

.Validator {
    max-width: 720px;
    margin: 50px auto;
    background-color: white;
    opacity: 0.8;
    border-radius: 20px;
}

.card-body {
    padding: 40px 30px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 0;
}

.subcard {
    width: 100%;
}

.card .card-subtitle {
    font-weight: 300;
    margin-bottom: 10px;
    color: #8898aa;
}
.table-product.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f8fa!important
}
.table-product td{
    border-top: 0px solid #dee2e6 !important;
    color: #728299!important;
}
.pdf-responsive{
    width: 430px;
    height: 530px;
}
.pdf-responsive iframe{
    width: inherit;
    height: inherit;
}
/*.watermarked {
    position: relative;
    overflow: hidden;
  }*/
  
  /*.watermarked img {
    width: 100%;
  }*/
  
  .pdf-responsive::before {
    position: absolute;
    top: 200px;
    left: -55px;
    
    display: block;
    width: 130%;
    height: 10%;
    
    transform: rotate(-50deg);
    content: attr(data-watermark);
    
    opacity: 0.7;
    line-height: 3em;
    letter-spacing: 2px;
    color: rgb(255, 0, 0);
  }