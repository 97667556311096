.react-pdf__Document {
    overflow-x: scroll;
}
.gr-canva {
    width: min-content;
    position: relative;
}
.canvas-container, .rec-canva, .mycanvas {
    position:absolute !important;
    top:0;
    left:0;
    display:flex;
}

.mycanvas , .lower-canvas{
    pointer-events:none;
    touch-action:manipulation;
} 

.upper-canvas{
    pointer-events:none;
}
  /*------
/* canvas[className*="fabric-canvas"] {
    position: absolute;
} */
