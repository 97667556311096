@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.BitSignDesk, .BigPrimeDesk {
	margin: 20px;
}

.init {
	max-width: 520px;
    margin: 50px auto;
	text-align: center;
}

.policy {
	max-width: 660px;
    margin: 50px auto;
	text-align: left;}

.init img {
	width: 300px;
}

.init p {
	font-family: 'Roboto', sans-serif;
	font-weight: bolder;
	margin-top: 70px;
}